<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" fill="none">
        <g :filter="`url(#filter0_f_269_23642-${uniqueId})`">
            <circle cx="26.8917" cy="26.8918" r="15.8125" transform="rotate(-146.774 26.8917 26.8918)" :fill="`url(#paint0_linear_269_23642-${uniqueId})`" />
        </g>
        <g :filter="`url(#filter1_f_269_23642-${uniqueId})`">
            <rect
                x="48.2452"
                y="20.6038"
                width="31.7083"
                height="30.7587"
                rx="15.3793"
                transform="rotate(118.869 48.2452 20.6038)"
                :fill="`url(#paint1_linear_269_23642-${uniqueId})`"
                fill-opacity="0.5" />
        </g>
        <defs>
            <filter
                :id="`filter0_f_269_23642-${uniqueId}`"
                x="0.902477"
                y="0.902493"
                width="51.9786"
                height="51.9786"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="5.0875" result="effect1_foregroundBlur_269_23642" />
            </filter>
            <filter :id="`filter1_f_269_23642-${uniqueId}`" x="5.51117" y="5.26434" width="43.2229" height="43.596" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_269_23642" />
            </filter>
            <linearGradient :id="`paint0_linear_269_23642-${uniqueId}`" x1="20.0168" y1="41.3292" x2="35.1417" y2="13.1418" gradientUnits="userSpaceOnUse">
                <stop offset="0.115" stop-color="#C89EF7" />
                <stop offset="0.29" stop-color="#C89EF7" />
                <stop offset="0.37" stop-color="#D78CE0" />
                <stop offset="0.73" stop-color="#F5D6A1" />
                <stop offset="1" stop-color="#F5D6A1" />
            </linearGradient>
            <linearGradient :id="`paint1_linear_269_23642-${uniqueId}`" x1="70.4411" y1="34.0903" x2="51.7667" y2="46.6774" gradientUnits="userSpaceOnUse">
                <stop offset="0.26" stop-color="#F6CCB5" stop-opacity="0" />
                <stop offset="1" stop-color="#FF27CC" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { useId } from "vue";
const uniqueId = useId();
</script>
