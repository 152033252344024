<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" fill="none">
        <g :filter="`url(#filter0_f_269_23640-${uniqueId})`">
            <rect
                x="32.8506"
                y="33.9091"
                width="18.8563"
                height="18.9279"
                rx="9.42814"
                transform="rotate(-151.279 32.8506 33.9091)"
                :fill="`url(#paint0_linear_269_23640-${uniqueId})`" />
        </g>
        <g :filter="`url(#filter1_f_269_23640-${uniqueId})`">
            <circle cx="26.3371" cy="26.1681" r="15.8125" transform="rotate(103.721 26.3371 26.1681)" :fill="`url(#paint1_linear_269_23640-${uniqueId})`" />
        </g>
        <defs>
            <filter
                :id="`filter0_f_269_23640-${uniqueId}`"
                x="11.4463"
                y="3.37492"
                width="35.3679"
                height="35.4079"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="4.125" result="effect1_foregroundBlur_269_23640" />
            </filter>
            <filter
                :id="`filter1_f_269_23640-${uniqueId}`"
                x="0.345898"
                y="0.176937"
                width="51.9823"
                height="51.9823"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="5.0875" result="effect1_foregroundBlur_269_23640" />
            </filter>
            <linearGradient :id="`paint0_linear_269_23640-${uniqueId}`" x1="46.05" y1="42.2083" x2="37.2391" y2="51.9075" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F6CCB5" stop-opacity="0" />
                <stop offset="0.67" stop-color="#F99474" />
            </linearGradient>
            <linearGradient :id="`paint1_linear_269_23640-${uniqueId}`" x1="19.4621" y1="40.6056" x2="34.587" y2="12.4181" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00C2FD" />
                <stop offset="0.395" stop-color="#58D5FB" />
                <stop offset="0.565" stop-color="#9EF9F1" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { useId } from "vue";
const uniqueId = useId();
</script>
