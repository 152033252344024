<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" fill="none">
        <g :filter="`url(#filter0_f_269_23645-${uniqueId})`">
            <rect
                x="32.4832"
                y="18.3359"
                width="18.8563"
                height="18.9279"
                rx="9.42814"
                transform="rotate(105 32.4832 18.3359)"
                :fill="`url(#paint0_linear_269_23645-${uniqueId})`" />
        </g>
        <g :filter="`url(#filter1_f_269_23645-${uniqueId})`">
            <circle cx="26.5076" cy="26.4998" r="15.8125" transform="rotate(15.7324 26.5076 26.4998)" :fill="`url(#paint1_linear_269_23645-${uniqueId})`" />
        </g>
        <defs>
            <filter
                :id="`filter0_f_269_23645-${uniqueId}`"
                x="3.18893"
                y="7.31226"
                width="35.4252"
                height="35.3622"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="4.125" result="effect1_foregroundBlur_269_23645" />
            </filter>
            <filter :id="`filter1_f_269_23645-${uniqueId}`" x="0.51609" y="0.508266" width="51.983" height="51.983" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="5.0875" result="effect1_foregroundBlur_269_23645" />
            </filter>
            <linearGradient :id="`paint0_linear_269_23645-${uniqueId}`" x1="45.6826" y1="26.6351" x2="36.8717" y2="36.3343" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F6CCB5" stop-opacity="0" />
                <stop offset="0.67" stop-color="#F99474" />
            </linearGradient>
            <linearGradient :id="`paint1_linear_269_23645-${uniqueId}`" x1="19.6326" y1="40.9373" x2="34.7576" y2="12.7498" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EE67CA" />
                <stop offset="0.295" stop-color="#EE67CA" />
                <stop offset="0.715" stop-color="#FCC3D8" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { useId } from "vue";
const uniqueId = useId();
</script>
