<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" fill="none">
        <g :filter="`url(#filter0_f_269_23648-${uniqueId})`">
            <circle cx="26.6714" cy="26.7034" r="15.8125" transform="rotate(9.35771 26.6714 26.7034)" :fill="`url(#paint0_linear_269_23648-${uniqueId})`" />
        </g>
        <g :filter="`url(#filter1_f_269_23648-${uniqueId})`">
            <rect
                x="35.0327"
                y="9.48828"
                width="28"
                height="28.1063"
                rx="14"
                transform="rotate(71.1772 35.0327 9.48828)"
                :fill="`url(#paint1_linear_269_23648-${uniqueId})`"
                fill-opacity="0.9" />
        </g>
        <defs>
            <filter
                :id="`filter0_f_269_23648-${uniqueId}`"
                x="0.681438"
                y="0.713497"
                width="51.9798"
                height="51.9798"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="5.0875" result="effect1_foregroundBlur_269_23648" />
            </filter>
            <filter
                :id="`filter1_f_269_23648-${uniqueId}`"
                x="3.94942"
                y="5.01805"
                width="44.5974"
                height="44.5113"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="4.125" result="effect1_foregroundBlur_269_23648" />
            </filter>
            <linearGradient :id="`paint0_linear_269_23648-${uniqueId}`" x1="19.7964" y1="41.1409" x2="34.9213" y2="12.9534" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1F50FF" />
                <stop offset="0.25" stop-color="#1F50FF" />
                <stop offset="0.699341" stop-color="#ADBCFE" />
                <stop offset="1" stop-color="#ADBCFE" />
            </linearGradient>
            <linearGradient :id="`paint1_linear_269_23648-${uniqueId}`" x1="54.6327" y1="21.8118" x2="41.5493" y2="36.2143" gradientUnits="userSpaceOnUse">
                <stop stop-color="white" stop-opacity="0" />
                <stop offset="0.835221" stop-color="#1DB4FF" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { useId } from "vue";
const uniqueId = useId();
</script>
