<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" fill="none">
        <g :filter="`url(#filter0_f_269_23646-${uniqueId})`">
            <rect
                x="32.1633"
                y="18.3359"
                width="18.8563"
                height="18.9279"
                rx="9.42814"
                transform="rotate(105 32.1633 18.3359)"
                :fill="`url(#paint0_linear_269_23646-${uniqueId})`" />
        </g>
        <g :filter="`url(#filter1_f_269_23646-${uniqueId})`">
            <circle cx="26.1877" cy="26.5" r="15.8125" :fill="`url(#paint1_linear_269_23646-${uniqueId})`" />
        </g>
        <defs>
            <filter
                :id="`filter0_f_269_23646-${uniqueId}`"
                x="2.86902"
                y="7.31225"
                width="35.4252"
                height="35.3623"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="4.125" result="effect1_foregroundBlur_269_23646" />
            </filter>
            <filter
                :id="`filter1_f_269_23646-${uniqueId}`"
                x="0.200187"
                y="0.512508"
                width="51.975"
                height="51.975"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="5.0875" result="effect1_foregroundBlur_269_23646" />
            </filter>
            <linearGradient :id="`paint0_linear_269_23646-${uniqueId}`" x1="45.3627" y1="26.6351" x2="36.5518" y2="36.3343" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F6CCB5" stop-opacity="0" />
                <stop offset="0.67" stop-color="#F99474" />
            </linearGradient>
            <linearGradient :id="`paint1_linear_269_23646-${uniqueId}`" x1="19.3127" y1="40.9375" x2="34.4377" y2="12.75" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F478A3" />
                <stop offset="0.28" stop-color="#FF5BA5" />
                <stop offset="0.425" stop-color="#F68475" />
                <stop offset="0.536242" stop-color="#F68475" />
                <stop offset="0.745" stop-color="#F1C472" />
                <stop offset="1" stop-color="#F1C472" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script setup>
import { useId } from "vue";
const uniqueId = useId();
</script>
